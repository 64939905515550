export const address: string[] = [
    "Joseph Bucknell House", 
    "30 South Maple Avenue, Suite 204", 
    "Marlton, New Jersey 08054", 
    // "Tel. (856) 701-7454", 
    // "Facsimile (856) xxx-xxxx"
]

export const phoneNumbers: string[] = [
    "Phone: 856.938.8578", 
    "Fax: 609.244.8827", 
    "Email: Fearnzimmer@gmail.com"
]